#installers-page-content {
    margin-top: 3em;
}

.search-button {
    margin-top: 0 !important;
}

.search-button.disabled {
    background: grey !important;
}

.ant-empty .ant-empty-description {
    margin-top: 0 !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 16px !important;
    color: rgba(0,0,0,.55) !important;
}

#captcha-instructions {
    margin-top: 1rem;
    font-size: 1rem;
    color: #555 !important; 
}

.ant-row.installers-banner {
    margin: 0 0 2rem 0 !important;
    border: 1px solid #003476;
    background: #568cca13;
    border-radius: 6px;
    width: 100%;
    font-size: 1rem;
    box-sizing: border-box;
}

.ant-row.installers-banner p{
    margin: 0;
    padding: 0.5rem;
}