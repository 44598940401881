html {
  font-size: 100%;
}

@media (max-width: 1540px) {
  html {
    font-size: 80%;
  }
}

body {
  font-family: 'Comfortaa', sans-serif !important;
  //font-family: -apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,Comfortaa,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important;
  margin: 0;
}

input::placeholder {
  color: $grey8 !important;
}

input {
  color: $grey23 !important;
}

input[disabled] {
  color: $grey8 !important;
}

a:link,
a:visited {
  color: $purple3;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: $purple2;
  background-color: transparent;
  text-decoration: none;
}
