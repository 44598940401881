.app-container {
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  display: block;
}

.ant-layout-header {
  padding: unset!important;
}

.app-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff !important;

  &__header {
    background-color: $blue1 !important;
    color: $blue2 !important;
    height: auto !important;
    border-bottom: 8px solid #046ec5!important;
    //padding: $size28 $size200 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    }

    &__logo {
      margin-left: $size32;
      width: $size180;
      cursor: pointer;
    }

    &__logo-gr {
      margin-top: $size10;
      margin-right: $size12;
      width: $size164;
    }

    &__buttons {
      display: flex;
      & > button:first-child {
        margin-right: $size20;
      }
    }

    &__content {
      padding: $size60 $size200;
      & > div {
        height: 100%; 
        display: flex;
        min-height: calc(62vh);  
        flex-direction: column;
        //justify-content: space-between;
      }
    }
}

.ant-layout {
  background: unset!important;
  min-height: 100%!important;
}

.app-layout__header_landing {
  background-color: #003476 !important;
  align-items: center;
  height: 100px !important;
  border-bottom: 8px solid #046ec5;
  //padding: $size28 $size180 !important;
  width: 100%;
  display: flex;
}

.app-layout__sub_header_landing {
  background-color:  #F5F5F5 !important;
  align-items: center;
  height: 100px !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.59);
  padding: $size28 $size180 !important;
  width: 100%;
  display: flex;

  .header-right {
    display: flex;
    justify-content: end;
    .title {
      font-size: 2.3rem;
      font-weight: 400!important;
      color: #003476;
    }
  }

}

.header-left {
  display: flex;
  justify-content: space-between;
  margin-right: 3rem;
  margin-left: 8.65%;
}

.header-right {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  .header-links {
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    margin-right: 12%;
  }

  .header-link a {
    color: #FFFFFF;
    margin: 10px;
    text-decoration: none;
  }

  .header-link a:hover{
    color: #afafaf!important;
    text-decoration: none;
  }
}

.header-icon-mobile {
  display: none;
}

.header-links-mobile {
  position: absolute;
  left: 0px;
  top: 100px;
  width: 100%;
  z-index: 1;
  background-color: #003375;
  display: flex;
  flex-direction: column;
  padding: 2rem;

  .header-paragraph {
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    width: 100%;
    p {
      line-height: 2rem;  
    }

    .contact-form-link{
      display: flex;
      justify-content: center;
      font-style: italic;
    }

    a {
      color: #FFFFFF;
    }
    
     a:hover{
      color: #afafaf!important;
      text-decoration: none;
    }
  }

  .header-link {
    color: #FFFFFF;
    margin-left: 3rem;
    line-height: 2.5rem;
    ul li {
      list-style-type: none;
      margin-left: 1.5rem;
    }
  }

  .header-link a{
    margin-left: -0.1rem;
    color: #FFFFFF;
    text-decoration: none;
  }

  .header-link a:hover{
    color: #afafaf!important;
    text-decoration: none;
  }
}

.ant-menu {
  background: #003375!important;
}

.ant-menu-submenu, .ant-menu-item {
  color: #FFFFFF; 
}
.ant-menu-sub {
  border-top: 5px solid #0065B3;
  background: #003375!important;
  color: #FFFFFF; 
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right: unset!important;
}

.ant-menu-submenu-popup {
  z-index: 10000!important;
}

.ant-menu-item a {
  color: #FFFFFF!important;
  text-decoration: none!important;

  &:hover {
    color: #afafaf!important;
  }
}

.ant-menu-item-selected a, .ant-menu-item-selected a:hover{
  color: unset!important;
}

.ant-menu-item-selected {
  color: #FFFFFF!important;
  background: unset!important;
}

.ant-menu-vertical .ant-menu-submenu-selected, .ant-menu-vertical-left .ant-menu-submenu-selected, .ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #FFFFFF!important;;
}

.ant-tooltip .ant-tooltip-content .ant-tooltip-arrow .ant-tooltip-arrow-content{
  display: none;
}

.ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow {
  color: #FFFFFF!important;
  transform: rotate(90deg)!important;
}

.ant-menu-title-content:hover {
  color: #afafaf!important;
}

.header-tooltip-operator {
  margin-top: -0.7rem!important;
}

.operator{
  display: flex;
  align-items: center;
  justify-content: center;

  & .operator-downoutline {
    font-size: 0.7rem;
    color: #fff;
    margin-left: 0.2rem;
  }

  &:hover {
      filter: invert(30%) ;
  }
}

.header-link-program {
  &:hover{
  color: #afafaf!important;
  cursor: pointer;
}
}

.program-downoutline {
  color: #FFFFFF!important;
  font-size: 0.8rem;

  &:hover {
    color: #afafaf!important;
  }
}

.ant-tooltip .ant-tooltip-content .ant-tooltip-inner {
  border-top: 5px solid #0065B3;
  background-color: #003375!important;
  color: #FFFFFF!important;
  padding: 2rem!important;

  .header-tooltip-operator-left {
    width: 30rem;
    height: 35rem;  
  }

  .header-tooltip-operator-left-span {
    float:left;
    width:50%;
    line-height: 1.6rem;

    a {
      font-style: italic;
    }

    a:nth-last-child(1) {
      font-style: unset;
    }
  }

  span {
    font-size: 1rem;
    
    a {
      color: #FFFFFF;
      font-weight: bold;
      line-height: 2rem;
    }
  
    a:hover{
      color: #afafaf!important;
      text-decoration: none;
    }
  }
}

.app-layout__logo-gr-democracy {
  margin-right: 2rem;
  cursor: pointer;
}

.app-layout__logo-gr-2-0 {
  margin-top: $size20;
  cursor: pointer;
}

.app-layout__logo-digital {
  transform: scale(130%);
  cursor: pointer;
}

.app-layout__logo-ktp {
  transform: scale(110%);
  cursor: pointer;
}

.ktp_logo, .digital_logo {
  cursor: pointer;
}

.badges {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  flex-wrap: wrap;
}

// .sub-header-image {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }

.sub-header-image #hero-text {
  color: #134383;
  text-align: center;
  font-family: Comfortaa;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 143.75%;
  position: relative;
  bottom: 5rem;
  width: 54%;
  margin: auto;
}

.sub-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.sub-header-wifi {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 8rem;
}

.sub-header-top-text {
  color: #A71680;
font-family: Comfortaa;
font-size: 1.5rem;
font-style: normal;
font-weight: 300;
line-height: normal;
letter-spacing: 0.96px;
}

.sub-header-btm-text {
  color: #86BC24;
font-family: Comfortaa;
font-size: 4.75rem;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 3.04px;
}

.sub-badge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
  width: 90%;

  & img {
    object-fit: cover;
  }

  & .badges-content {
    width: 90%;
  }
}

.maintenance-message {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  margin: 0 12rem;
  font-size: 2rem;
}

.ant-btn {
  display: flex;
  background-color: #86bc24!important;
  color: #FFFFFF!important;
  padding: 4px 10px 4px 12px;
  height: fit-content!important;
  width: fit-content!important;
  margin-right: 6px;
  margin-top: 6px;
  gap: 4px;
  // padding: 15px 40px 15px 40px !important;
  border-radius: 38px !important;

  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.2px;

  // &.active-page {
  //   color: #afafaf!important;
  // }
}

.badges-content {
  background-color: #FFFFFF !important;
  color: #FFFFFF!important;
  border-radius: 16px!important;
  font-weight: 400;
  // padding: 4px 10px 4px 12px;
  // margin-right: 6px;
  margin-top: 6px;
  gap: 4px;

  a {
    color: #FFFFFF!important;
  }

  
  a.active-page {
    color: #afafaf!important;
  }

  a:link { 
    text-decoration: none;
    color: #FFFFFF!important;
  }

  a:hover { 
    text-decoration: none;
    color: #FFFFFF!important;
  }
  a:active { 
    text-decoration: none;
    color: #FFFFFF!important;
  }
}

.main-content {
  width: 80% !important;
  height: 100%;
  background: white!important;;
  align-items:center;
  margin: 2rem auto;
  margin-bottom: 15rem !important;

  &__collapse {
    border: unset!important;
    border-radius: 9px!important;
    margin-top: 10px!important;
  }

  .ant-collapse-header {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.5rem;
    // background: #1890ff;
    background: #86bc24;
    border-radius: 38px !important;
  }

  .header-blue{
    color: #1782D5;
    margin-top: 2rem;
  }

  .ant-collapse {
    font-size: 1rem;
    font-weight: 400;
    background-color: #FFFFFF;
  }

  .ant-collapse-header {
    padding: 17px 40px 16px 18px !important;
  }

  .ant-collapse-item {
    border-bottom: unset!important;
  }

  .ant-collapse-content {
    border-radius: 8px!important;
  }

  .ant-collapse-item-active {
    border: 1px solid #BDBDBD!important;
    border-radius: 8px!important;

    & .ant-collapse-header {
      background: #FFFF;
    }

    & .ant-collapse-content {
      border-top: unset;
    }
  }

  &__table  {
    & .ant-table-content {
      border-radius: 8px!important;
      border: 1px solid #86bc24;
    }

    // & .ant-table-thead {
    //   background: #1782D5;
    // }
  
    & .ant-table-thead {
      & tr th {
      font-weight: 700;
      font-size: 0.75rem !important;
      line-height: 1.5rem !important;
      background: #86bc24!important;
      color: #FFFFFF;
    }}

    & .ant-table-thead tr th:first-child{
      border-radius: 6px 0px 0px 0px!important;
    }

    & .ant-table-thead tr th:last-child{
      border-radius: 0px 6px 0px 0px!important;
    }

    & .ant-table-tbody tr:last-child td:first-child{
      border-radius: 0px 0px 0px 7px!important;
    }

    & .ant-table-tbody tr:last-child td:last-child{
      border-radius: 0px 0px 7px 0px!important;
    }
  }
}

.collapse-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  text-align: center;
  word-wrap: break-word;
  margin-bottom: 6rem;
  margin-top: 7rem;
  gap: 4.063rem;

  h1 {
    font-size: 1.875rem;
  }

  & > .ant-btn {
    padding: 1.875rem 2rem !important;
    font-size: 1.875rem;
    border-radius: 38px !important;
    line-height: normal;
    width: 39.8rem !important;

    & span {
      margin: auto;
    }
  }
}

.documents-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dcdcdc;
  height: 8rem;
  font-size: 1.875rem;
  font-weight: 700;
}

.documents-items {
  display: flex;
  flex-direction: row;
  justify-content: space-around; /* Changed from space-between to space-around */
  margin-top: 2rem;
  font-size: 1rem;
}

.docs {
  flex: 1; 
  text-align: center;
  margin: 0 1rem; 
}

.docs:first-child {
  margin-left: 0; 
}

.docs:last-child {
  margin-right: 0; 
}

.documents-category {
  display: flex;
  justify-content: center;
  background: #86BC24;
  color: #FFFFFF;
  height: 5rem;
  align-items: center;
  margin-bottom: 1.5rem;
  font-weight: 700;
}

.documents-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    text-decoration: none!important;
    color: #333!important;
    font-weight: 500;
    font-size: 1.4375rem;
    word-wrap: break-word;
  }
}

.taa-banner {
  height: 788px;
  width: 100%;
  background-color: #4682b4;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 42px;
  font-weight: 700;
  line-height: 71, 4px;
  align-items: center;
  background-image: url('../../assets/icons/banner-background.png');
  background-position: center center;
}

.taa-content {
  width: 60%;
  text-align: center;
  align-items: center;
  padding-bottom: 0px;
}

.taa-paragraph {
  margin: 15px;
}

.welcome-banner {
  width: 70% !important;
  height: 100%;
  background: #f2f2f2;
  align-items: center;
  margin: 100px auto;
}

.welcome-title {
  color: #003476;
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 50px;
}

.welcome-paragraph {
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  margin-bottom: 50px;
  text-align: justify;
}

.login-button {
  width: 289px !important;
  height: 62px !important;
  background: #003476 !important;
  border-radius: 8px !important;
  color: #fff !important;
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: space-between;

  > * {
    margin-top: 10px;
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  > span {
    margin-top: 15px;
  }
}

.pylwnes {
  height: 453px;
  background: #003476;
  width: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.pylwnes-title {
  margin-top: 80px;
  margin-bottom: 80px;
  font-size: 1.5rem;
  font-weight: 700;
}

.pylwnes-icons {
  margin-bottom: 50px;
  width: 100%;
}

.first-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.first-row > div {
  width: 30%;
}

.cycle1 {
  margin-bottom: 20px;
}

.cycle2 {
  margin-bottom: 30px;
}

.cycle3 {
  margin-bottom: 23px;
}

.contents-icons-description {
  width: 58%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.second-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.second-row > div {
  margin: 20px;
  display: flex;
  flex-direction: column;
  width: 30%;
}

.contents-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/*.contents-icons > div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}*/

.contents-icons .contents-icons-description{
  font-weight: 700;
  color: #FFFFFF;
  font-size: 1rem;
}

.cycle3 {
  margin-top: 20px;
}

.cycle3-description {
  margin-top: 10px;
}

.landing-page-footer-smart {
  height: 8.125rem;
  position:relative;
  bottom:0;
  width: 100%;
}

.landing-page-footer {
  background: #f2f2f2;
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer {
  // background: #f2f2f2;
  border-top: 10px solid #0065B3;
  position:absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  flex-wrap: wrap;

  & .footer-logo {
    padding: 0 1.5rem;
  }

  // & #environment-ministry-logo {
  //   transform: scale(1.1);
  // }
}

.landing-page-footer > * {
  margin: 30px;
}

.landing-page-footer p {
  font-size: 16px;
  font-weight: 400;
  color: #003476;
}

.footer-logo {
  cursor: pointer;
}

.foooter-paragraph {
  //width: 60%;
  text-align: center;
}

a:link {
  text-decoration: underline;
}

a:visited {
  text-decoration: none;
  color: #003476;
}

a:hover {
  color: #003476 !important;
  text-decoration: none;
}

a:active {
  text-decoration: underline;
}

.content-title {
  font-size: $size28;
  font-weight: 500;
  margin-bottom: $size4;
}

.content-paragraph {
  margin-top: $size32;
  font-size: $size16;
  text-align: justify;
  line-height: normal;
}

.contents-icons {
  width: 20rem;
  //height: 8rem;
  //margin: 0 1.2rem;
}

.ant-alert-icon {
  width: 100%;
  height: 100%;
}

.ant-alert-description {
  display: flex;
  text-align: center;
  flex-direction: column;
}

.ant-alert-warning {
  border: none !important;
  background-color: white !important;
}

.ant-empty-description,
.ant-alert-description p {
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: $blue2;
  margin-bottom: 1.5rem;
  margin-top: 50px;
}

.ant-alert-description span {
  font-size: 16px;
}

.ant-alert-description img {
  margin-top: 120px;
  height: 320px !important;
}

.ant-alert-description-message {
  display: flex;
  flex-direction: column;
}

/* Mobile devices*/
@media only screen and (min-width: 701px) and (max-width: 991px) {
  .app-layout__header_landing {
    display: flex;
    flex-direction: column;
    height: 11rem!important;
  }

  .app-layout__sub_header_landing {
    padding: 0px!important;
    display: flex;
    flex-direction: column;
    height: 100%!important;

    .header-right {
      display: flex;
      justify-content: center;
    }
  }

  .header-left {
    margin-top: 0.5rem;
    margin-left: 2rem;
  }

  .header-right {
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .header-links {
      display: none;
    }

    .header-icon-mobile {
      display:flex;
      justify-content: center;
      align-items: center;
      color: #046ec5;
      font-size: 2.5rem;
      margin-top: -0.5rem;
    }

    .header-links-mobile {
      top: 11rem;
      line-height: 3.5rem;

      .operator-icon {
        margin-top: 2rem;
        margin-bottom: 1rem;
        margin-top: 2rem;
        margin-left: auto;
        margin-right: auto;
      }
      
      .header-paragraph {
        font-size: 1.25rem;
        text-align: center;
      }
    }
  }

  .app-layout__logo-gr-democracy {
    margin-left: 60px;
  }
 
  .app-layout__logo-gr-digital-transformation {
    transform: scale(105%);
  }

  .app-layout__logo-gr-2-0 {
    transform: scale(85%);
  }

  .documents-items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 2rem;
    font-size: 1rem;

    .docs {
      margin: 0px!important;
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1119px) {
  .app-layout__header_landing {
    display: flex;
    flex-direction: column;
    height: 11rem!important;

    .header-right {
      display: flex;
      justify-content: center;
    }
  }

  .app-layout__sub_header_landing {
    padding: 0px!important;
    // height: 5rem!important;
    display: flex;
    flex-direction: column;
    height: 100%!important;

    .header-right {
      display: flex;
      justify-content: center;
    }
  }

  .header-left {
    margin-top: 0.5rem;
    margin-left: 2rem;
  }

  .header-right {
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    .header-links {
      display: none;
    }

    .header-icon-mobile {
      display:flex;
      justify-content: center;
      align-items: center;
      color: #046ec5;
      font-size: 2.5rem;
      margin-top: -0.5rem;
    }

    .header-links-mobile {
      top: 11rem;
      line-height: 3.5rem;

      .operator-icon {
        margin-top: 2rem;
        margin-bottom: 1rem;
        margin-top: 2rem;
        margin-left: auto;
        margin-right: auto;
      }
      
      .header-paragraph {
        font-size: 1.25rem;
        text-align: center;
      }
    }
  }

  .app-layout__logo-gr-democracy {
    margin-left: 60px !important;
  }

  .app-layout__logo-gr-2-0 {
    margin-right: 60px !important;
  }

  .header-left {
    margin-left: -2rem;
  }

  .documents-items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 2rem;
    font-size: 1rem;

    .docs {
      margin: 0px!important;
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1280px) {
  .app-layout__logo-gr-democracy {
    margin-left: 10px; //60
  }

  .app-layout__logo-gr-2-0 {
    margin-right: 10px; //60
  }

  .header-links-mobile {
    display: none;
  }
}

@media only screen and (min-width: 1119px) and (max-width: 1281px) {
  .app-layout__sub_header_landing {
    & .contents-icons {
        margin-left: 1rem;
      }
  }

  .header-links-mobile {
    display: none;
  }

  .sub-header-image #hero-text {
    width: 70%;
  }

  .documents-items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 2rem;
    font-size: 1rem;

    .docs {
      margin: 0px!important;
    }
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1950px) {
  // .app-layout__sub_header_landing {
  //   & .contents-icons {
  //       margin-left: 3.4rem;
  //     }
  // }
}

@media only screen and (max-width: 1118px) {
  .sub-header-image #hero-text {
    width: 70%;
    font-size: 0.875rem;
    bottom: 4rem;
  }
}

@media only screen and (max-width: 700px) {
  .badges {
    display: flex;
    flex-direction: column;
  }

  .sub-header-image #hero-text {
    width: 90%;
    font-size: 0.625rem;
    bottom: 3rem;
  }

  .main-content {
    margin-top: 1rem;

    &__table  {
      & .ant-table-thead tr th{
        padding: 0.5rem;
      }
    }
  }

  .app-layout__header_landing {
    display: flex;
    flex-direction: column;
    height: 11rem !important;
  }

  .app-layout__logo-gr-digital-transformation {
    transform: scale(140%);
  }

  .app-layout__logo-gr-2-0 {
    transform: scale(120%);
  }

  .header-left {
    margin-top: 0.5rem;
    margin-left: 2rem;
  }

  .header-right {
    width: 80%;
    .header-links {
        display: none;
      }

      .header-icon-mobile {
        display:flex;
        justify-content: center;
        align-items: center;
        margin-left: 2rem;
        color: #046ec5;
        font-size: 2.5rem;
        margin-top: -0.5rem;
      }

      .header-links-mobile {
        top: 9rem;
        line-height: 3.5rem;

        .operator-icon {
          margin-top: 2rem;
          margin-bottom: 1rem;
          margin-top: 2rem;
          margin-left: auto;
          margin-right: auto;
        }
        
        .header-paragraph {
          font-size: 1.25rem;
          text-align: center;
        }
      }
  }

  .app-layout__sub_header_landing {
    padding: 0px!important;
    display: flex;
    flex-direction: column;
    height: 100%!important;

    .header-left {
      margin: 0px!important
    }

    .header-right {
      display: flex;
      justify-content: center;
    }
  
    & .contents-icons {
      width: 13rem;
      & .app-layout__logo-digital {
        transform: scale(150%);
        padding-left: 0.8rem;
      }
    }
  }

  .documents-items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 2rem;
    font-size: 1rem;

    .docs {
      margin: 0px!important;
    }
  }

  .landing-page-footer-smart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 8.125rem;
    position: relative;
    margin-top: 25rem;
  }
  
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .taa-banner {
    height: 100%;
    text-align: center;
    font-size: 20px;
    padding: 20px;
    font-weight: 400;
  }

  .taa-icon {
    width: 85px;
  }

  .welcome-banner {
    height: 100%;
    margin-top: 50px;
  }

  .welcome-title {
    text-align: center;
  }

  .welcome-paragraph {
    text-align: justify;
  }

  .pylwnes {
    height: 100%;
    display: flex;
    text-align: center;
  }

  .pylwnes-title {
    font-size: 20px;
  }

  .pylwnes-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }

  .pylwnes-icons > * {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
  }

  .first-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
  }

  .first-row > div {
    margin: 20px;
    display: flex;
    flex-direction: column;
    width: 30%;
  }

  .second-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .second-row > div {
    margin: 20px;
    display: flex;
    flex-direction: column;
    width: 30%;
  }

  .contents-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; 
  }

  .contents-icons > div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }

  .contents-icons > * {
    text-align: center;
  }

  .landing-page-footer {
    text-align: center;
  }

  .app-layout__header_landing {
    padding: 0px !important;
    margin: 0px !important;
  }

  .app-layout__logo-gr-democracy {
    margin-left: 30px !important;
    width: 90%;
  }

  .app-layout__logo-gr-2-0 {
    //margin-top: 20px !important;
    //margin-left: 20px !important;
    padding-top: 18px;
    margin: 0 !important;
    padding-left: 40px;
    padding-right: 20px;
    width: 100%;
    cursor: pointer;
  }

  .footer-logo {
    margin: 12px;
    width: 80%;
    cursor: pointer;
  }
}

@media only screen and (max-width: 500px) {

  .collapse-header > .ant-btn {
    padding: 1rem 1rem;
  
    & span {
      font-size: 80%;
      white-space: break-spaces;
    }
  }

  .sub-header-image #hero-text {
    width: 90%;
    font-size: 0.5rem;
    bottom: 2rem;
  }

  .ant-layout-content {
    &.sub-header-wifi svg {
      transform: scale(0.8);
    }

    // &.sub-header *{
    //   font-size: 100%;
    // }
    
    &.badges button {
      transform: scale(0.9);
    }
  }
}

  .map{
    height: 500px;
    border: 1px solid #1782D5 !important;
    border-radius: 8px !important;
    margin-bottom:5em;
  }


  .input-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px !important;
    background: #ffffff !important;
    border: 1px solid #1782D5 !important;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
    border-radius: 8px !important;
    flex: none;
    align-content: left;
    order: 1;
    width: 1516px;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 2.5em;
  }

  

  .ant-select{
    width:100%;
  }

  .results-table .ant-table-thead tr th {
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 0.875rem !important;
    color: #ffffff !important;
    background: #1782D5 !important;
    width: 1516px;
  }

  .ant-input-group-addon {
    display: none!important;
}



.captchaContainer{
  margin-top:1rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.warning-message{
  font-size: $size15;
  line-height: 16px;
  color: $grey4;
  margin-bottom: 5rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  text-align: center;
  background-color:#ffa618;
  color: 'black';
}


.instructions{
  font-size: $size14;
  line-height: 14px;
  color: $blue2;
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: left;
}

.smart-icon {
  width: 400px;
  height: 400px;
}

@media only screen and (max-width: 400px) {

  .smart-icon {
    width: 100%;
    height: auto;
  }
}

.bot {
  position: fixed;
  right: 10px;
  bottom: 25px;
  z-index:2,
}

@media only screen and (max-width: 600px) {
  .bot {
    position: fixed;
    right: 15%;
    bottom: 10%;
    z-index:2,
  }
}

.bot-minus {
  background: #ffffff;
  width: 10%;
  margin-left:2%;
  position: relative;
  height: 1.25rem;
  bottom: 3.438rem;
  margin-bottom: -4.8rem;
  border-radius: 1.25rem
}

@media only screen and (max-width: 600px) {
  .bot-minus {
    width: 12%;
    margin-left:3%;
  }
}

.bot-white{
  background: #ffffff;
  width: 99.2%;
  margin-left:0.2%;
  position: relative;
  height: 3.125rem;
  bottom: 3.438rem;
  border-radius: 20px
}

.bot-minimize {
  position: fixed;
  right: 10px;
  bottom: 25px;
  z-index:2, 
}

@media only screen and (max-width: 600px) {
  .bot-minimize {
    position: fixed;
    right: 15%;
    bottom: 10%;
    z-index:2,
  }
}

.bot-iframe{
  width:37.5rem;
  height:40rem;
  box-shadow: 0px 0px 7px 6px #7f7f7f6e;
  border-radius: 20px
}

@media only screen and (max-width: 600px) {
  .bot-iframe{
    width:350px;
    height:35rem;
    box-shadow: 0px 0px 7px 6px #7f7f7f6e;
    border-radius: 20px
  }
}